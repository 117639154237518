<template>
  <div>
    <el-row
      type="flex"
      justify="end"
    >
      <div
        @click="collapse = !collapse"
        class="mr-2"
      >
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Filtrar Relato"
        >
          <i
            slot="reference"
            :class="collapse? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <br>
    <vuestic-widget v-show="collapse">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <multiselect
                v-model="areaSelecionada"
                placeholder="Área"
                label="nome"
                selectLabel="Selecione"
                :options="areas"
                :multiple="true"
                :key="'id'"
                id="search-area"
                selectedLabel="Selecionado"
                deselectLabel="Pressione para remover"
                track-by="id"
                :close-on-select="false"
                :preserve-search="true"
              >
                <span slot="noResult">Área não encontrada</span>
              </multiselect>
              <label
                class="select-control-label"
                for="search-area"
              > Pesquisar por área</label>
            </div>
          </div>
        </div>
        <div class="row mr-1 justify-content-end">
          <el-button
            type="danger"
            icon="el-icon-close"
            @click="limparPesquisa"
          >
            Limpar
          </el-button>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-widget>
      <div class="convainer mt-4">
        <div class="row">
          <el-transfer
            class="m-auto"
            filterable
            filter-placeholder="Funcionários"
            v-model="acesso.funcionario"
            :data="funcionariosFiltrados"
            :titles="['Funcionários', 'Funcionários Selecionados']"
            :props="props"
          >
          </el-transfer>
        </div>

        <div class="mt-4 row d-flex justify-content-end">
          <button
            class="btn btn-pale btn-micro mr-2"
            @click="push('lista-checklist')"
          >Voltar</button>
          <button
            class="btn btn-primary btn-micro"
            @click="submit()"
          >Confirmar</button>
        </div>
      </div>
    </vuestic-widget>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

import notificationMixin from '@/utils/notification';

export default {
  mixins: [notificationMixin],
  props: {
    routerSuffix: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      collapse: false,
      idChecklist: null,
      areaSelecionada: [],
      areas: [],
      funcionarios: [],
      funcionariosFiltrados: [],
      acesso: {
        empresa: null,
        checklist: null,
        funcionario: [],
      },
      props: {
        label: 'nome',
        key: 'id',
      },
    };
  },
  created() {
    this.idChecklist = Number(this.$route.params.checklist);
    this.acesso.checklist = this.idChecklist;
    this.getFuncionarios();
    this.getAcesso();
    this.getArea();
  },
  methods: {
    ...mapActions('acesso', [
      'getAcessos',
      'postAcesso',
      'putAcesso',
    ]),
    ...mapActions('funcionario', ['getFuncionariosList']),
    ...mapActions('area', ['getAreasList']),
    changeFuncionario() {
      if (this.areaSelecionada.length > 0) {
        this.funcionariosFiltrados = this.funcionarios.filter(
          f => f.areas.some(
            fs => this.areaSelecionada.map(area => area.id).includes(fs),
          ),
        );
      } else {
        this.funcionariosFiltrados = this.funcionarios;
      }
    },
    filterMethod(query, item) {
      return item.nome.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    submit() {
      if (this.acesso.id) {
        this.editAcesso();
      } else {
        this.cadastrarAcesso();
      }
    },
    push(name, params) {
      this.$router.push({
        name: `${name}${this.routerSuffix}`,
        params,
      });
    },
    async getAcesso() {
      try {
        const empresa = localStorage.empresaSelecionada;
        const res = await this.getAcessos({ params: { checklist: this.idChecklist, empresa } });
        this.acesso = { ...res[0] };
      } catch (error) {
        console.error(error);
      }
    },
    async getArea() {
      try {
        this.areas = await this.getAreasList();
      } catch (error) {
        console.error(error);
      }
    },
    async getFuncionarios() {
      try {
        const res = await this.getFuncionariosList();
        const empresa = Number(localStorage.empresaSelecionada);
        this.funcionarios = res.filter(f => f.empresas.includes(empresa));
        this.changeFuncionario();
      } catch (error) {
        console.error(error);
      }
    },
    async cadastrarAcesso() {
      try {
        await this.postAcesso(this.acesso);
        this.areaSelecionada = null;
        this.message('success', 'Acesso Salvo com sucesso');
        this.push('lista-checklist');
      } catch (error) {
        console.error(error);
        this.message('error', 'Erro ao tentar salvar o acesso');
      }
    },
    async editAcesso() {
      try {
        await this.putAcesso(this.acesso);
        this.message('success', 'Acesso Salvo com sucesso');
        this.push('lista-checklist');
      } catch (error) {
        console.error(error);
        this.message('error', 'Erro ao tentar salvar o acesso');
      }
    },
    limparPesquisa() {
      this.areaSelecionada = [];
    },
  },
  watch: {
    areaSelecionada() {
      this.changeFuncionario();
    },
  },
};
</script>

<style>
.el-transfer-panel {
	width: 300px !important;
}
</style>
